.slowFade {
  display: flex;
  align-items: flex-start;
  background: #fff;
  height: 50vh;
  min-height: 350px;
  overflow: hidden;
  position: relative;
}
.slowFade .slide img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  height: auto;
  background: #000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  transform: scale(1.5);
  -webkit-animation: slowFade 32s infinite;
  animation: slowFade 32s infinite;
}
.slowFade .slide:nth-child(3) img {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.slowFade .slide:nth-child(2) img {
  -webkit-animation-delay: 9s;
  animation-delay: 9s;
}
.slowFade .slide:nth-child(1) img {
  -webkit-animation-delay: 17s;
  animation-delay: 17s;
}
@keyframes slowFade {
  25% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
  40% {
    opacity: 0;
  }
}
@-webkit-keyframes slowFade {
  25% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
  40% {
    opacity: 0;
  }
}
